<!-- 文件中文名: 购物车 -->
<template>
  <div class="ShoppingCart app-container">
    <div v-loading="doLoading">
      <div
          v-show="purchaseInfo && this.shoppingList.length > 0"
          style="padding: 10px"
      >
        <div class="purchase-info">
          <p>
            <i class="el-icon-warning"/>
            <!--存在订单类型不同的商品,请查看-->
            {{ $t('hint.1') }}
          </p>
          <p
              :class="{ 'p-black': btnBlack }"
              class="p-color"
              @click="clickPBlack"
              @mouseenter="btnBlack = true"
              @mouseleave="btnBlack = false"
          >
            X
          </p>
        </div>
      </div>
      <!--购物车-->
      <h2 style="text-align: left">{{ $t('label.shopping.cart') }}</h2>
      <div v-if="this.shoppingList.length > 0">
        <el-form label-width="auto" style="text-align: left">
          <!--经销商编号-->
          <el-form-item v-show="!MemberRegisterData" :label="$t('miMember.memberNo')">
            <el-input @input="value=>inputMemberNo=value.toUpperCase()" v-model="inputMemberNo" style="width: 30%" class="AppStyle" @blur="getInputMemberNoMessages"/>
            &nbsp; <span style="color: red">{{ inputMemberNo2 }}</span>
            <span style="color: green; font-weight: 700">&nbsp;&nbsp;{{ this.$t(this.inputManager) }}</span>
          </el-form-item>
          <!--订单类型-->
          <el-form-item :label="$t('pd.orderType')">
            <el-radio-group v-model="orderType" @change="setOrderType">
              <el-radio :label="17" class="shopping-btn" v-show="sg">
                <!--新注册首购单-->
                <span style="font-size: 20px">{{ $t('NewRegisteredFirstOrder_es_ES') }}</span>
              </el-radio>
              <el-radio :label="18" class="shopping-btn" v-show="sj">
                <!--升级单-->
                <span style="font-size: 20px">{{ $t('miMemberUpgradeNote.updateRemark') }}</span>
              </el-radio>
              <el-radio :label="19" class="shopping-btn" v-show="cx">
                <!--重消单-->
                <span style="font-size: 20px">{{ $t('poOrder.uprec.order') }}</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-table
            :data="this.shoppingList"
            :row-class-name="tableRowClassName"
            class="el-table-border GoodsList"
            style="width: 100%"
        >
          <el-table-column align="center">
            <template slot-scope="scope">
              <el-button
                  :title="$t('button.delete')"
                  icon="el-icon-delete"
                  plain
                  size="small"
                  type="danger"
                  @click="clickDelete(scope.row.goodsCode)"
              />
            </template>
          </el-table-column>
          <el-table-column :label="$t('image')" align="center">
            <template slot-scope="{ row }">
              <el-image
                  :src="row.imgsrc"
                  fit="contain"
                  style="width: 100%; height: 100%; border: 0.5px #eeeeee solid"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column :label="$t('sr.productName')" align="center" prop="goodsName">
            <template slot-scope="{ row }">
              {{ $t(row.goodsName) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('Product.Code')" align="center" prop="goodsCode"/>
          <el-table-column :label="$t('biBarCode.quantity')" align="center" width="140">
            <template slot-scope="{ row }">
              <el-input-number
                  v-model.number="row.quantity"
                  :min="1"
                  size="small"
                  @change="getTotalPrice(row.quantity)"
              />
            </template>
          </el-table-column>
          <!--价格-->
          <el-table-column :label="$t('pd.price')" align="right">
            <template slot-scope="{ row }">
              <p v-show="orderType===17||orderType===18">
                {{ row.standardPriceB === 0 ? '--' : row.standardPriceB }}{{ $symbol() }}
              </p>
              <p v-show="orderType===19" style="font-size: 24px;">
                {{ row.proPriceB === 0 ? '--' : row.proPriceB }}{{ $symbol() }}
              </p>
            </template>
          </el-table-column>
          <!--总价格-->
          <el-table-column :label="$t('TotalPrice')" align="right">
            <template slot-scope="{ row }">
              <p v-show="orderType===17||orderType===18">
                {{ getHighPrecisionMul(row.standardPriceB, row.quantity) }}{{ $symbol() }}</p>
              <p v-show="orderType===19" style="font-size: 24px">
                {{ getHighPrecisionMul(row.proPriceB, row.quantity) }}{{ $symbol() }}</p>
            </template>
          </el-table-column>
          <el-table-column :label="$t('P.V')" align="right">
            <template slot-scope="{ row }">
              <p v-show="orderType===17||orderType===18"> {{ row.standardPv === 0 ? '--' : row.standardPv }}</p>
              <p v-show="orderType===19"> {{ row.proPv === 0 ? '--' : row.proPv }}</p>
            </template>
          </el-table-column>
          <el-table-column :label="$t('company.me.totalPV')" align="right">
            <template slot-scope="{ row }">
              <p v-show="orderType===17||orderType===18"> {{ getHighPrecisionMul(row.standardPv, row.quantity) }}</p>
              <p v-show="orderType===19"> {{ getHighPrecisionMul(row.proPv, row.quantity) }}</p>
            </template>
          </el-table-column>
          <!--积分-->
          <el-table-column v-if="orderType===19" :label="$t('busi.direct.sale_points_es_ES')" align="right" prop="proF000">
            <template slot-scope="{row}">
              {{ row.proF000 === 0 ? '--' : row.proF000 }}
            </template>
          </el-table-column>
          <!--总积分-->
          <el-table-column v-if="orderType===19" :label="$t('miagent.fv.total')" align="right">
            <template slot-scope="{ row }">
              <p v-show="orderType===19">
                {{ getHighPrecisionMul(row.proF000, row.quantity) }}</p>
            </template>
          </el-table-column>
        </el-table>
        <div class="purchase-info">
          <div style="font-size: 18px;white-space: pre-wrap;" v-show="ifGoods">
            <span>{{ goodsCode }}</span>
            <span>{{ $t('goods.stock.NoSpot') + '!' }} </span>
          </div>
        </div>
        <div style="display: flex;justify-content: end;padding: 10px;">
          <table class="TotalPrice-Table">
            <tbody>
            <tr>
              <td v-if="orderType !== 19" style="border-right: 1px solid #dddddd; padding: 5px">
                <!--级别-->
                <strong>{{ $t('commond.levelType') }}</strong>
              </td>
              <td v-if="!MemberRegisterData && orderType !== 19" style="border-right: 1px solid #dddddd; padding: 5px">
                <el-select v-model="selectlevel" @change="setSjLevel">
                  <!--<el-option :disabled="option1" label="100" value="10"></el-option>-->
                  <el-option :disabled="option2" label="300" value="20"></el-option>
                  <el-option :disabled="option3" label="500" value="30"></el-option>
                  <el-option :disabled="option4" label="1500" value="40"></el-option>
                </el-select>
              </td>
              <td v-if="totalFcSG && orderType !== 19" style="border-right: 1px solid #dddddd; padding: 5px">
                <el-select v-model="selectlevel">
                  <!--<el-option :disabled="option1" label="100" value="10"></el-option>-->
                  <el-option :disabled="option2" label="300" value="20"></el-option>
                  <el-option :disabled="option3" label="500" value="30"></el-option>
                  <el-option :disabled="option4" label="1500" value="40"></el-option>
                </el-select>
              </td>
              <td style="border-right: 1px solid #dddddd; padding: 5px">
                <strong>{{ $t('TotalPrice') + (':') }}</strong>
              </td>
              <td style="border-right: 1px solid #dddddd;padding: 5px">
                <p v-show="orderType===17||orderType===18">{{ TotalPrice7 }}{{ $symbol() }}</p>
                <p v-show="orderType===19">{{ TotalPrice6 }}{{ $symbol() }}</p>
              </td>
              <!--            <td style="border-right: 1px solid #dddddd;padding: 5px">-->
              <!--              <p v-show="orderType===17||orderType===18">{{ TotalPrice5 >= 999999 ? '&#45;&#45;' : TotalPrice5 }}W$</p>-->
              <!--              <p v-show="orderType===19">{{ TotalPrice1 >= 999999 ? '&#45;&#45;' : TotalPrice1 }}W$</p>-->
              <!--            </td>-->
              <td style="border-right: 1px solid #dddddd; padding: 5px">
                <strong>{{ $t('company.me.totalPV') + (':') }}</strong>
              </td>
              <td style="border-right: 1px solid #dddddd; padding: 5px">
                <p v-show="orderType===17||orderType===18">{{ TotalPrice2 }}</p>
                <p v-show="orderType===19">{{ TotalPrice3 }}</p>
              </td>
              <td v-show="orderType===19" style="border-right: 1px solid #dddddd; padding: 5px">
                <strong>{{ $t('miagent.fv.total') + (':') }}</strong>
              </td>
              <td v-show="orderType===19" style="border-right: 1px solid #dddddd; padding: 5px">
                <p>{{ TotalPrice4 === 0 ? '--' : TotalPrice4 }}</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <!--          促销组件-->
          <publicpage :PV="TotalPrice3" :orderType="orderType" :memberLevelType="memberLevelType.toString()" :shoppingList="shoppingList" :selectlevel="selectlevel"></publicpage>
          <!--          套组选尺码组件-->
          <SetupSizeSelection @LAN00001924_Change="LAN00001924_Change" :buy_LAN00001924_num="buy_LAN00001924_num" :shoppingList="shoppingList"  :orderType="orderType" ></SetupSizeSelection>
          <!--三点促销-->
          <SaOneOrThree :time-show="SaOneOrThreeShow" :goods-list="this.shoppingList" :order-type="orderType"
                        @promTypeChange="promTypeChange"
                        @LAN00001424_valid="LAN00001424_valid"
                        @LAN00001424_invalid="LAN00001424_invalid" />
<!--          LA-N000020-25-->
          <LAN00002025  :goods-list="this.shoppingList" :order-type="orderType"
                        @LAN00002025_valid="LAN00002025_valid"
                        @LAN00002025_invalid="LAN00002025_invalid" />
        </div>
        <!-- 防止塌陷 -->
        <div style="clear: both"></div>
        <div style="display: flex; justify-content: space-between">
          <!-- 继续购买Btn   !17 -->
          <el-button
              :class="{ 'btn-color-green': ContinueColor }"
              class="btn-continue"
              @click="clickContinue"
              @mouseenter.native="ContinueColor = true"
              @mouseleave.native="ContinueColor = false"
          >{{ $t('btn.continue.buy') }}
          </el-button>
          <!--          下单-->
          <el-button
              :loading="doLoading"
              :disabled="SjError&&orderType!==19"
              plain
              type="success"
              @click="getIsthere">
            {{ $t('place.order') }}
          </el-button>
        </div>
        <div style="text-align: left; margin-top: 0.5rem">
          <!-- 放弃新注册首购Btn     17   -->
          <el-button
              v-show="orderType===17&&MemberRegisterData"
              :loading="doLoading"
              plain
              type="danger"
              @click="clearMemberRegister">
            {{ $t('AbandonNewlyRegistered') }}
          </el-button>
        </div>
      </div>
      <div v-if="this.shoppingList.length === 0" style="padding: 5px 10px">
        <h4 style="text-align: left;">{{ $t('hint.2') }}</h4>
        <el-button plain style="float: right" type="warning" @click="clickContinue">{{ $t('btn.continue') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import publicpage from "@/components/Promotions/publicpage/index.vue";
import SetupSizeSelection from "@/components/Promotions/publicpage/Setup_size_Selection.vue";
import {goodsStockAlert, memberNoCanOrder, memberUpOrderCheck, orderPVAlert} from "@/api/rus/api"
import SaOneOrThree from "@/components/Promotions/SaOneOrThree.vue";
import LAN00002025 from "@/components/Promotions/LA-N000020-25.vue";
import {RUSAugustPromotion} from "@/api/promotion/time";

export default {
  name: "Shopping-Cart",
  directives: {},
  components: {SaOneOrThree, publicpage,SetupSizeSelection,LAN00002025},
  data() {
    return {
      // LA-N000020-25
      LAN00002025_XDList: [],
      LAN00002025_YJList: [],
      // LA-N000014-24
      LAN00001424_XDList: [],
      LAN00001424_YJList: [],
      //套组选尺码
      ChooseSizeList:[],
      LAN00001924_num:0,
      buy_LAN00001924_num:0,
      LAN00001924_list:[],
      //
      totalFcSG: '',
      giftObj: '',
      giftGoodsCode: '',
      memberLevelType: '',
      option1: false,
      option2: false,
      option3: false,
      option4: false,
      levelNumber: 0, // 级别对应的已购pV
      quantity: '',
      MemberRegisterData: null,
      inputMemberNo2: '',
      inputManager: '',
      inputMemberNo: '',
      sg: 0,
      sj: 0,
      cx: 0,
      chapv: '',
      doLoading: false,
      SjError: false,
      selectlevel: '',
      TotalPrice1: 0,
      TotalPrice2: 0,
      TotalPrice3: 0,
      TotalPrice4: 0,
      TotalPrice5: 0,
      TotalPrice6: 0,
      TotalPrice7: 0,
      orderType: null,
      ContinueColor: false,
      btnBlack: false,
      purchaseInfo: false,
      success: false,
      goodsCode: [],
      ifGoods: false,
      SaOneOrThreeShow: false,
      promType: null
    };
  },
  computed: {
    ...mapGetters(["shoppingList", "userCode", 'companyCode']),
  },
  watch:{
    shoppingList:{
      deep:true,
      handler:function (val){
        this.buy_LAN00001924_num = 0
        val.forEach(v=>{
          if(v.goodsCode === 'LA-N000019-24'){
            this.buy_LAN00001924_num = v.quantity
          }
        })
      },
      immediate:true
    },
  },
  async created() {
    sessionStorage.removeItem('ChooseSizeList')
    sessionStorage.removeItem('PromType')
    this.ChooseSizeList = []
    this.ifLogOnSG()
    this.ifSpot()
    this.MemberRegisterData = JSON.parse(sessionStorage.getItem("MemberRegisterData"))
    //  判断是否是登录后注册首购单
    if (this.MemberRegisterData) {
      this.orderType = 17
      this.sg = 1
    } else {
      this.inputMemberNo = this.userCode
      this.getInputMemberNoMessages()
    }
    this.getTotalPrice()
    let { data } = await RUSAugustPromotion()
    this.SaOneOrThreeShow = data
  },
  methods: {
    promTypeChange(val) {
      this.promType = val
    },
    LAN00001424_valid(data){
      this.LAN00001424_XDList = data.XD_list;
      this.LAN00001424_YJList = data.YJ_list;
    },
    LAN00001424_invalid(message){
      return this.$message.error(this.$t(message))
    },
    LAN00002025_valid(data){
      this.LAN00002025_XDList = data.XD_list;
      this.LAN00002025_YJList = data.YJ_list;
    },
    LAN00002025_invalid(message){
      return this.$message.error(this.$t(message))
    },
    // 促销选尺码
    LAN00001924_Change(e){
      this.LAN00001924_num = 0
      this.LAN00001924_list = e
      this.LAN00001924_list.forEach(v=>{
        this.LAN00001924_num += v.quantity
      })
    },
    getHighPrecisionMul(num1, num2) {
      return this.highPrecisionMul(num1, num2) === 0 ? '--' : this.highPrecisionMul(num1, num2)
    },
    // 判断是否是登录新注册首购单
    ifLogOnSG() {
      this.totalFcSG = sessionStorage.getItem('totalFc')
      this.option1 = false
      this.option2 = false
      this.option3 = false
      this.option4 = false
      const levelMapping = {
        "1500": '40',
        "500": '30',
        "300": '20',
        "100": '10'
      }
      this.selectlevel = levelMapping[this.totalFcSG] || ''
    },
    // 判断是否现货
    ifSpot() {
      let num = []
      this.goods = this.shoppingList
      for (let index = 0; index < this.shoppingList.length; index++) {
        if (this.shoppingList[index].isSpot == 0) {
          num.push(this.shoppingList[index].goodsCode)
          this.ifGoods = true
          this.goodsCode = String(num);
        }
      }
    },
   getInputMemberNoMessages() {
      this.orderType = null
      if (this.inputMemberNo) {
        this.doLoading = true
        this.memberLevelType = ''
        this.orderType = 0
      memberNoCanOrder({memberNo: this.inputMemberNo}).then(res => {
          this.doLoading = false
          this.inputMemberNo2 = this.inputMemberNo
          this.inputManager = res.data?.manager
          this.sg = res.data.sg
          this.sj = res.data.sj
          this.cx = res.data.cx
          this.memberLevelType = res.data.levelType
          // 获取输入经销商级别
          const levelSettings = {
            0: {levelNumber: 0, option1: false, option2: false, option3: false, option4: false},
            10: {levelNumber: 100, option1: true, option2: false, option3: false, option4: false},
            20: {levelNumber: 300, option1: true, option2: true, option3: false, option4: false},
            30: {levelNumber: 500, option1: true, option2: true, option3: true, option4: false},
            // 注意：对于40，我们可能需要一个特殊的处理函数，因为它只设置了orderType
          }
          this.selectlevel = ''

          // 根据levelType设置属性
          if (Object.prototype.hasOwnProperty.call(levelSettings, res.data.levelType)) {
            const settings = levelSettings[res.data.levelType]
            this.levelNumber = settings.levelNumber
            this.option1 = settings.option1
            this.option2 = settings.option2
            this.option3 = settings.option3
            this.option4 = settings.option4
          } else if (res.data.levelType === 40) {
            // 对于40的特殊处理
            this.orderType = 19
          } else {
            // 如果levelType不是预期的任何一个，这里可以添加默认处理或日志记录
            console.warn('Unknown levelType:', res.data.levelType)
          }
        }).catch(err => {
          this.doLoading = false
          this.sg = ''
          this.sj = ''
          this.cx = ''
          this.inputMemberNo2 = ''
          this.inputManager = ''
          console.warn(err)
        })
      } else {
        this.orderType = null
        this.sg = ''
        this.sj = ''
        this.cx = ''
        this.inputMemberNo2 = ''
        this.inputManager = ''
        console.log('输入为空!')
      }
    },
    // 计算总和
    getTotalPrice() {
      this.TotalPrice1 = 0
      this.TotalPrice2 = 0
      this.TotalPrice3 = 0
      this.TotalPrice4 = 0
      this.TotalPrice5 = 0
      this.TotalPrice6 = 0
      this.TotalPrice7 = 0
      for (let i = 0; i < this.shoppingList.length; i++) {
        this.shoppingList[i].proPrice = this.shoppingList[i].proPrice >= 999999 ? 0 : this.shoppingList[i].proPrice
        this.shoppingList[i].standardPv = this.shoppingList[i].standardPv >= 999999 ? 0 : this.shoppingList[i].standardPv
        this.shoppingList[i].proPv = this.shoppingList[i].proPv >= 999999 ? 0 : this.shoppingList[i].proPv
        this.shoppingList[i].proF000 = this.shoppingList[i].proF000 >= 999999 ? 0 : this.shoppingList[i].proF000
        this.shoppingList[i].standardPrice = this.shoppingList[i].standardPrice >= 999999 ? 0 : this.shoppingList[i].standardPrice
        this.shoppingList[i].proPriceB = this.shoppingList[i].proPriceB >= 999999 ? 0 : this.shoppingList[i].proPriceB
        this.shoppingList[i].standardPriceB = this.shoppingList[i].standardPriceB >= 999999 ? 0 : this.shoppingList[i].standardPriceB

        this.TotalPrice1 = this.highPrecisionAdd(this.TotalPrice1, this.highPrecisionMul(this.shoppingList[i].proPrice, this.shoppingList[i].quantity))
        this.TotalPrice2 = this.highPrecisionAdd(this.TotalPrice2, this.highPrecisionMul(this.shoppingList[i].standardPv, this.shoppingList[i].quantity))
        this.TotalPrice3 = this.highPrecisionAdd(this.TotalPrice3, this.highPrecisionMul(this.shoppingList[i].proPv, this.shoppingList[i].quantity))
        this.TotalPrice4 = this.highPrecisionAdd(this.TotalPrice4, this.highPrecisionMul(this.shoppingList[i].proF000, this.shoppingList[i].quantity))
        this.TotalPrice5 = this.highPrecisionAdd(this.TotalPrice5, this.highPrecisionMul(this.shoppingList[i].standardPrice, this.shoppingList[i].quantity))
        this.TotalPrice6 = this.highPrecisionAdd(this.TotalPrice6, this.highPrecisionMul(this.shoppingList[i].proPriceB, this.shoppingList[i].quantity))
        this.TotalPrice7 = this.highPrecisionAdd(this.TotalPrice7, this.highPrecisionMul(this.shoppingList[i].standardPriceB, this.shoppingList[i].quantity))
      }
      this.$store.dispatch('shopping/replaceShoppings', this.shoppingList)
    },
    clickDelete(goodsCode) {
      console.log(goodsCode,'goodsCodegoodsCodegoodsCode')
      if(goodsCode === 'LA-N000019-24'){
        this.buy_LAN00001924_num = 0
      }
      if (this.goodsCode != '') {
        const arr = this.goodsCode.split(',');
        let index = arr.indexOf(goodsCode);
        if (index != -1) {
          arr.splice(index, 1)
        }
        this.goodsCode = String(arr);
      }
      if (this.goodsCode == '') {
        this.ifGoods = false
      }

      this.$store.dispatch('shopping/deleteSomeone', goodsCode)
      this.getTotalPrice()
    },
    // 关闭信息
    clickPBlack() {
      this.purchaseInfo = false;
    },
    // 继续购买
    clickContinue() {
      this.$router.push({path: '/'})
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({row, rowIndex}) {
      if (this.orderType === 19) {
        if (row.chongxiao == 1) {
          return 'error-row'
        }
      } else if (this.orderType === 18) {
        if (row.shengji == 1) {
          return 'error-row'
        }
      } else {
        if (row.shougou == 1) {
          return 'error-row'
        }
      }
      return ''
    },
    setOrderType(val) {
      if (val === 19) {
        this.purchaseInfo = !!this.shoppingList.some(ele => ele.chongxiao === 1)
      } else if (val === 18) {
        this.purchaseInfo = !!this.shoppingList.some(ele => ele.shengji === 1)
      } else {
        this.purchaseInfo = !!this.shoppingList.some(ele => ele.shougou === 1)
      }
      sessionStorage.setItem("orderType", JSON.stringify(val))
    },
    setSjLevel(val) {
      this.doLoading = false
      sessionStorage.setItem("selectlevel", JSON.stringify(val))
      if (!this.sg) {
        memberUpOrderCheck({memberNo: this.inputMemberNo, selectlevel: val}).then(res => {
          this.doLoading = false
          this.SjError = false
          this.chapv = res.data.chapv
        }).catch(err => {
          this.doLoading = false
          this.SjError = true
          console.warn(err)
        })
      }
      const totalAmount = this.levelNumber + +this.TotalPrice2
      orderPVAlert({memberNo: this.inputMemberNo, selectlevel: val, totalAmount}).then(res => {
        this.success = res.success
      }).catch(err => {
        this.doLoading = false
        console.warn(err)
      })
    },
    // 下单 判断库存r
    getIsthere() {
      // 套组选尺码
      this.ChooseSizeList = []
      sessionStorage.removeItem('ChooseSizeList')
      if(this.orderType === 17 || this.orderType === 18){
        if(this.buy_LAN00001924_num * 2 !==  this.LAN00001924_num ){
          return this.$message.error(this.$t('The.quantity.of.products'))
        }else{
          this.LAN00001924_list.forEach(v=>{
            if(v.quantity > 0){
              this.ChooseSizeList.push(v)
            }
          })
        }
      }
      // LA-N000014-24
      if (this.LAN00001424_XDList) {
        this.LAN00001424_XDList.forEach((item) => {
          const existingItem = this.ChooseSizeList.find((existingItem) => existingItem.goodscode === item.goodscode);
          if (existingItem) {
            existingItem.quantity += item.quantity;
          } else {
            this.ChooseSizeList.push({ ...item }); // 创建对象副本
          }
        });
      }

      if (this.LAN00001424_YJList) {
        this.LAN00001424_YJList.forEach((item) => {
          const existingItem = this.ChooseSizeList.find((existingItem) => existingItem.goodscode === item.goodscode);
          if (existingItem) {
            existingItem.quantity += item.quantity;
          } else {
            this.ChooseSizeList.push({ ...item }); // 创建对象副本
          }
        });
      }

// LA-N000020-25
      if (this.LAN00002025_XDList) {
        this.LAN00002025_XDList.forEach((item) => {
          const existingItem = this.ChooseSizeList.find((existingItem) => existingItem.goodscode === item.goodscode);
          if (existingItem) {
            existingItem.quantity += item.quantity;
          } else {
            this.ChooseSizeList.push({ ...item }); // 创建对象副本
          }
        });
      }

      if (this.LAN00002025_YJList) {
        this.LAN00002025_YJList.forEach((item) => {
          const existingItem = this.ChooseSizeList.find((existingItem) => existingItem.goodscode === item.goodscode);
          if (existingItem) {
            existingItem.quantity += item.quantity;
          } else {
            this.ChooseSizeList.push({ ...item }); // 创建对象副本
          }
        });
      }

      if(this.ChooseSizeList.length >0){
        sessionStorage.setItem('ChooseSizeList',JSON.stringify(this.ChooseSizeList))
      }

      if (this.orderType !== 19) {
        if (!this.selectlevel) {
          return this.$message.error(this.$t('register.us.select.cardtype'))
        }
      }
      if (this.orderType === 17) {
        let filterRes = this.shoppingList.filter(item => item.goodsCode === 'LA-N000014-24')[0]
        let quantity = filterRes?.quantity || 0
        if (quantity&&!this.promType) {
          this.$message.error(this.$t('mi.input.select') + ' ' + this.$t('po.rightQty.1or3'))
          return
        } else {
          if (this.promType === '10') {
            sessionStorage.setItem("promType", '10')
          } else {
            sessionStorage.setItem("promType", '0')
          }
        }
        if(quantity && (this.LAN00001424_XDList.length===0 || this.LAN00001424_YJList.length===0)){
          return this.$message.error(this.$t('The.quantity.of.products'))
        }
      } else {
        sessionStorage.setItem("promType", '0')
      }
      if(this.orderType !== 19){
        let LAN00001424_filterRes = this.shoppingList.filter(item => item.goodsCode === 'LA-N000014-24')[0]
        let LAN00001424_quantity = LAN00001424_filterRes?.quantity || 0
        const LAN00001424_XD = this.LAN00001424_XDList.reduce((acc, item) => acc + item.quantity, 0);
        const LAN00001424_YJ = this.LAN00001424_YJList.reduce((acc, item) => acc + item.quantity, 0);
        if(LAN00001424_quantity * 2 !== LAN00001424_XD || LAN00001424_quantity * 2 !== LAN00001424_YJ ){
          return this.$message.error(this.$t('The.quantity.of.products'))
        }

        let LAN00002025_filterRes = this.shoppingList.filter(item => item.goodsCode === 'LA-N000020-25')[0]
        let LAN00002025_quantity = LAN00002025_filterRes?.quantity || 0
        const LAN00002025_XD = this.LAN00002025_XDList.reduce((acc, item) => acc + item.quantity, 0);
        const LAN00002025_YJ = this.LAN00002025_YJList.reduce((acc, item) => acc + item.quantity, 0);
        if(LAN00002025_quantity * 2 !== LAN00002025_XD || LAN00002025_quantity !== LAN00002025_YJ ){
          return this.$message.error(this.$t('The.quantity.of.products'))
        }
      }

      // 判断首购单
      if (this.orderType === 17) {
        if (this.TotalPrice2 >= 1500) {
          let filterRes = this.shoppingList.filter(item => item.goodsCode === 'LA-N000014-24')[0]
          let quantity = filterRes?.quantity || 0
          let promType = sessionStorage.getItem("promType")
          if (quantity>0 && this.selectlevel !== '40' && promType!=='10') {
            return this.$message.error(this.$t('mi.input.select') + '1500')
          }
        } else if (this.TotalPrice2 >= 500 && this.TotalPrice2 < 1500) {
          if (this.selectlevel !== '30') {
            return this.$message.error(this.$t('mi.input.select') + '500')
          }
        }  else if (this.TotalPrice2 >= 300 && this.TotalPrice2 < 500) {
          if (this.selectlevel !== '20') {
            return this.$message.error(this.$t('mi.input.select') + '300')
          }
        }
        // else if (this.TotalPrice2 >= 100 && this.TotalPrice2 < 300) {
        //   if (this.selectlevel !== '10') {
        //     return this.$message.error(this.$t('mi.input.select') + '100')
        //   }
        // } else if (this.TotalPrice2 < 100) {
        //   return this.$message.error('首购最低需要100PV')
        // }
      }
      // 判断升级单 下单条件
      if (this.orderType === 18) {
        // 已购PV + 购买总PV
        const pvsum = this.highPrecisionAdd(Number(this.levelNumber), Number(this.TotalPrice2))
        if (this.selectlevel === '40') {
          if (!(pvsum >= 1500)) {
            return this.$message.error('1500' + this.$t('commond.levelType') + this.$t('poor') + (1500 - pvsum) + 'PV')
          }
        } else if (this.selectlevel === '30') {
          if (pvsum >= 1500) {
            return this.$message.error(this.$t('mi.input.select') + '1500')
          } else if (pvsum < 500) {
            return this.$message.error('500' + this.$t('commond.levelType') + this.$t('poor') + (500 - pvsum) + 'PV')
          }
        } else if (this.selectlevel === '20') {
          if (pvsum >= 1500) {
            return this.$message.error(this.$t('mi.input.select') + '1500')
          } else if (pvsum >= 500) {
            return this.$message.error(this.$t('mi.input.select') + '500')
          } else if (pvsum < 300) {
            return this.$message.error('300' + this.$t('commond.levelType') + this.$t('poor') + (300 - pvsum) + 'PV')
          }
        }
      }

      const a = [...this.shoppingList.map(v => v.goodsCode)]
      goodsStockAlert(a)
      this.goOrderSettlePage()
    },
    goOrderSettlePage() {
      if (!this.orderType) {
        return this.$message.error(this.$t('poOrder.pleaseChoose') + ' ' + this.$t('pd.orderType') + '!')
      }
      sessionStorage.setItem("orderType", JSON.stringify(this.orderType))
      sessionStorage.setItem('OrderSettlement', JSON.stringify(this.shoppingList))
      sessionStorage.setItem('InputMemberNo', JSON.stringify(this.inputMemberNo))
      if (this.orderType === 19) { // orderType19
        if (this.shoppingList.some(e => e.chongxiao === 1)) {
          return this.$message.error(this.$t('hint.1').toString())
        }
        this.$router.push({path: '/order-settle'})  // 19的最终走向
      } else { // 17或者18时
        if (!this.selectlevel && !this.MemberRegisterData) {
          return this.$message.error(this.$t('register.us.select.cardtype').toString()) // 请选择级别
        }
        if (this.levelNumber + this.TotalPrice2 < this.chapv && !this.MemberRegisterData) {
          return this.$message.error(this.$t('error.lower.than.allowed').toString())
        }
        if (this.orderType === 18) {
          if (this.shoppingList.some(e => e.shengji === 1)) {
            this.$message.error(this.$t('hint.1').toString());
            return
          }
          this.$router.push({path: '/order-settle'});
        } else if (this.orderType === 17) {
          if (this.shoppingList.some(e => e.shougou === 1)) {
            this.$message.error(this.$t('hint.1').toString())
            return
          }
          if (!this.MemberRegisterData) {
            this.$router.push({path: '/order-settle'});
            return
          }
          const levelToFcMap = {
            '40': 1500,
            '30': 500,
            '20': 300,
            '10': 100
          }
          this.MemberRegisterData.poOrderVO.totalFc = levelToFcMap[this.selectlevel] || 0
          sessionStorage.setItem("MemberRegisterData", JSON.stringify(this.MemberRegisterData));
          this.$router.push({path: '/member-register/member-register-settle'});
        }
      }
    },
    clearMemberRegister() {
      sessionStorage.removeItem('totalFc')
      sessionStorage.removeItem('MemberRegisterData')
      sessionStorage.setItem("orderType", JSON.stringify(18))
      this.$router.go(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.purchase-info {
  text-align: left;
  color: #E6A23C;
  background-color: #FDF6EC;
  margin: 0;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid transparent;
  border-radius: 4px;
}

.p-black {
  color: black;
}

.horizontal-line {
  font-size: 14px;
  color: #a0a0a0;
  text-decoration: line-through;
  padding-left: 12px;
}

::v-deep .el-table.el-table-border {
  .el-table__cell {
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    padding: 0;
  }

  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.TotalPrice-Table {
  border: 1px solid #dddddd;
  margin: 10px 0;
  text-align: left;
}

.btn-continue {
  color: #ffffff;
  text-shadow: 0 0 0 rgb(255 255 255 / 50%);
  background-color: #e7e7e7;
  background-image: linear-gradient(to bottom, #ff5f00, #ff5f00);
  background-repeat: repeat-x;
}

.btn-color-green {
  background: #08a652;
}

.GoodsList ::v-deep .error-row {
  background: rgb(253, 226, 226) !important;
}

@media screen and (max-width: 991px) {
  .AppStyle {
    width: 100% !important;
  }
  .GoodsList {
    font-size: 12px;
  }
  .TotalPrice-Table {
    font-size: 10px;
    transform: scale(1);
  }
}
</style>
